<template>
  <div class="select-time-range">
    <div>

      <!--            <custom-number-input label="بازه هر تایم"-->
      <!--                                 :classes="{'w-full': true}"-->
      <!--                                 v-model="club.timeLength" />-->

      <div class="time-picker">
        <div class="date-picker-item">
          <custom-validate-input label="تاریخ شروع"
                                 :placeholder="'07:00'"
                                 :classes="{'w-full': true}"
                                 :regex="dateRegex"
                                 v-model="startTime"/>
          <date-picker style="color: #3c3c3c !important;"
                       color="#222222"
                       type="date"
                       v-model="startTime.value"
                       :max="endTime.value"/>
        </div>

        <div class="date-picker-item">
          <custom-validate-input label="تاریخ پایان"
                                 :placeholder="'23:00'"
                                 :classes="{'w-full': true}"
                                 :regex="dateRegex"
                                 v-model="endTime"/>
          <date-picker style="color: #3c3c3c !important;"
                       color="#222222"
                       type="date"
                       v-model="endTime.value"
                       :min="startTime.value"/>
        </div>
      </div>
    </div>

    <vs-button v-show="false"
               id="selectTimeRangeBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import moment from 'moment'
  import {getClubSetting, editClubSetting} from '../../../../http/requests/setting'
  import DatePicker from 'vue-persian-datetime-picker'
  import CustomSelect from "../../../../components/customSelect/customSelect";
  import CustomValidateTextarea from "../../../../components/customInput/customValidateTextarea";
  import CustomValidateInput from "../../../../components/customInput/customValidateInput";
  import CustomNumberInput from "../../../../components/customInput/customNumberInput";
  import {checkUserPermissions} from "../../../../assets/js/functions";
  import {getTimes} from "../../../../http/requests/times";

  export default {
    name: "selectTimeRange",
    components: {CustomNumberInput, CustomValidateInput, CustomValidateTextarea, CustomSelect, DatePicker},
    data() {
      return {
        dateRegex: this.$validator('regex.date'),
        timeRegex: this.$validator('regex.time'),
        timeLengthOptions: [
          {
            id: 1,
            label: '60 دقیقه',
            value: '60'
          },
          {
            id: 2,
            label: '90 دقیقه',
            value: '90'
          }
        ],
        startTime: {
          value: '',
          isValid: true
        },
        endTime: {
          value: '',
          isValid: true
        }
      }
    },

    created() {
      this.setDefaultTime()
    },
    methods: {
      setDefaultTime() {
        const time = this.$store.state.helper.calendarDate

        if (typeof time === 'string') {
          this.startTime.value = time
          this.endTime.value = time
        } else if (time.length === 1) {
          this.startTime.value = time[0]
          this.endTime.value = time[0]
        } else {
          this.startTime.value = time[0]
          this.endTime.value = time[1]
        }
      },
      sendData() {
        if (!this.startTime.isValid) {
          this.$vs.notify({
            title: 'خطا',
            text: 'تاریخ شروع وارد شده معتبر نیست',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }
        if (!this.endTime.isValid) {
          this.$vs.notify({
            title: 'خطا',
            text: 'تاریخ پایان وارد شده معتبر نیست',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }
        let time = []
        if (this.startTime.value === this.endTime.value) {
          time.push(this.startTime.value)
          this.$store.dispatch('helper/changeCalendarDate', time)
          this.$emit('edited')
          return true
        } else {
          time.push(this.startTime.value)
          time.push(this.endTime.value)
          this.$store.dispatch('helper/changeCalendarDate', time)
          this.$emit('edited')
        }
      }
    }
  }
</script>

<style lang="scss">
  .select-time-range {
    padding: 10px;
    padding-top: 20px;
    min-height: 200px;

    .time-picker {
      display: flex;
      justify-content: space-between;

      .date-picker-item {
        border: 0 !important;
        border-radius: 0.4rem;
        position: relative;
        flex-grow: 0.5;
        max-width: 48%;

        .custom-input {
          margin: 0;
        }

        .date-picker-label {
          font-size: 12px;
          position: absolute;
          top: -10px;
          left: 5px;
          padding: 0 5px;
          background-color: #ffffff;
          z-index: 1;
        }

        .vpd-input-group {
          width: 20px !important;
          position: absolute;
          top: 6px;
          left: 2px;

          .vpd-icon-btn {
            opacity: 1 !important;
            height: 25px !important;
            width: 20px !important;
            background-color: inherit !important;

            svg {
              fill: #000000;
              overflow: visible !important;
            }
          }

          .form-control {
            border: 0;
            width: 100%;
            display: none;
            line-height: 35px;
          }
        }
      }
    }
  }
</style>
